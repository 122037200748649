import React, { useState } from 'react';
import GBIGradeShield from '../../../util/logos/GBIGradeShield';
import CalculationParameter from './CalculationParameter';
import InfoIconThick from '../../../util/icons/components/InfoIconThick';
import history from '../../../goodBeefIndex/gbiHistory';
import { getDeadWeight,
  getHungWeight,
  getButcheredWeight,
  getRetailKilo,
  getFarmGateTotal,
  getFarmGateKilo,
  getNetRetail,
  getValueAdded,
  getFarmerShare,
  getSupplyShare,
  getEblexRetailValue } from '../util';
import './value-sharing.scss';
import FarmerRestricted from '../../../util/components/FarmerRestricted';

const ValueSharing = () => {
  const [ farmCost, setFarmCost ] = useState(1800);
  const [ farmReturn, setFarmReturn ] = useState(-25);
  const [ liveWeight, setLiveWeight ] = useState(700);
  const [ killOutLoss, setKillOutLoss ] = useState(50);
  const [ hangingLoss, setHangingLoss ] = useState(15);
  const [ butcheringLoss, setButcheringLoss ] = useState(29.5);
  const [ retailLoss, setRetailLoss ] = useState(10);

  const deadWeight = getDeadWeight(liveWeight, killOutLoss);
  const hungWeight = getHungWeight(deadWeight, hangingLoss);
  const butcheredWeight = getButcheredWeight(hungWeight, butcheringLoss);

  const grossRetailValue = getEblexRetailValue(butcheredWeight); // eblex value
  const grossRetailPerKilo = getRetailKilo(grossRetailValue, butcheredWeight);

  const farmGateTotal = getFarmGateTotal(farmCost, farmReturn);
  const farmGateKilo = getFarmGateKilo(farmCost, farmReturn, deadWeight);

  const netRetailValue = getNetRetail(grossRetailValue, retailLoss);
  const valueAdded = getValueAdded(netRetailValue, farmGateTotal);
  const farmerShare = getFarmerShare(farmGateTotal, netRetailValue);
  const supplyShare = getSupplyShare(valueAdded, netRetailValue);

  return (
    <FarmerRestricted>
      <div id="value-sharing" className="farmer-content-section">
        <h1 className="page-title" data-cy="value-sharing-title">
          Value Sharing
        </h1>
        <div className="section farm">
          <div className="content">
            <div className="label">Farm Cost</div>
            <CalculationParameter value={ farmCost }
              onChange={ setFarmCost }
              testId="farm-cost-input"
              inputClass="big" >
              <span className="denominator">£</span>
              <span className="big-number">
                { isNaN(farmCost) ? '-' : farmCost.toLocaleString() }
              </span>
            </CalculationParameter>
            <div className="sub-section">
              <div>Farm Return</div>
              <CalculationParameter
                value={ farmReturn }
                onChange={ setFarmReturn }
                testId="farm-return-input"
                inputClass="big" >
                <span className="big-number">
                  { isNaN(farmReturn) ? '-' : farmReturn }
                </span>
                <span className="denominator">%</span>
              </CalculationParameter>
              <div>Farm Gate Price</div>
              <div className="output">
                <div>
                  <span className="denominator">£</span>
                  <span className="value small-number"
                    data-cy="farm-gate-price">
                    { farmGateTotal === 0 ?
                      '-' : farmGateTotal.toLocaleString() }
                  </span>
                </div>
                <div>
                  <span className="denominator">£</span>
                  <span className="value small-number"
                    data-cy="farm-gate-kilo">
                    { farmGateKilo === '0' ? '-' : farmGateKilo }
                  </span>
                  <span className="denominator highlight">per kg</span>
                </div>
              </div>
            </div>
            <div className="label">
              Live Weight <span className="light">(LW)</span>
            </div>
            <CalculationParameter value={ liveWeight }
              onChange={ setLiveWeight }
              testId="live-weight-input"
              inputClass="big" >
              <span className="big-number">
                { isNaN(liveWeight) ? '-' : liveWeight }
              </span>
              <span className="denominator">kg</span>
            </CalculationParameter>
          </div>
          <div className="step-label">
            <GBIGradeShield grade="prime" />
            <div className="text">Farmer</div>
          </div>
        </div>

        <div className="boundary supply-chain">
          <span className="text">supply chain</span>
        </div>

        <div className="section carcass">
          <div className="content">
            <div className="label">
              Dead Weight <span className="light">(DW)</span>
            </div>
            <div className="big-figure">
              <span className="big-number"
                data-cy="dead-weight">
                { isNaN(deadWeight) ? '-' : Math.round(deadWeight) }
              </span>
              <span className="denominator">kg</span>
            </div>
            <CalculationParameter value={ killOutLoss }
              onChange={ setKillOutLoss }
              testId="kill-out-loss-input"
              inputClass="big" >
              <span>
                { isNaN(killOutLoss) ? '-' : Math.round(killOutLoss) }
              </span>
              <span className="denominator">%</span>
              <span className="highlight bold">Kill Out </span>
              <span className="highlight">kg loss</span>
            </CalculationParameter>
          </div>
          <div className="step-label">
            <div className="text">Carcass Specification</div>
          </div>
        </div>

        <div className="section hanging">
          <div className="content">
            <div className="label">
              Hung Weight <span className="light">(HW)</span>
            </div>
            <div className="big-figure">
              <span className="big-number"
                data-cy="hung-weight">
                { isNaN(hungWeight) ? '-' : Math.round(hungWeight) }
              </span>
              <span className="denominator">kg</span>
            </div>
            <CalculationParameter value={ hangingLoss }
              onChange={ setHangingLoss }
              testId="hanging-loss-input"
              inputClass="big" >
              <span>
                { isNaN(hangingLoss) ? '-' : Math.round(hangingLoss) }
              </span>
              <span className="denominator">%</span>
              <span className="highlight bold">Dry Hanging </span>
              <span className="highlight">kg loss</span>
            </CalculationParameter>
          </div>
          <div className="step-label">
            <div className="text">
              Dry Hung 21-28 Days
              <InfoIconThick onClick={ () => history.push('/') } />
            </div>
          </div>
        </div>

        <div className="section butcher">
          <div className="content">
            <div className="label">
              Butchered Weight <span className="light">(BW)</span>
            </div>
            <div className="big-figure">
              <span className="big-number"
                data-cy="butchered-weight">
                { isNaN(butcheredWeight) ? '-' : Math.round(butcheredWeight) }
              </span>
              <span className="denominator">kg</span>
            </div>
            <CalculationParameter value={ butcheringLoss }
              onChange={ setButcheringLoss }
              testId="butchering-loss-input"
              inputClass="big" >
              <span>
                { isNaN(butcheringLoss) ? '-' : Math.round(butcheringLoss) }
              </span>
              <span className="denominator">%</span>
              <span className="highlight bold">Butchering </span>
              <span className="highlight">kg Loss</span>
            </CalculationParameter>
            <div className="sub-section">
              <div>Gross Retail Value</div>
              <div className="output">
                <div>
                  <span className="denominator">£</span>
                  <span className="value small-number"
                    data-cy="gross-retail">
                    { isNaN(grossRetailValue) ?
                      '-' : grossRetailValue.toLocaleString(undefined, {
                        maximumFractionDigits: 0 }) }
                  </span>
                </div>
                <div>
                  <span className="denominator">£</span>
                  <span className="value small-number" data-cy="retail-kilo">
                    { isNaN(grossRetailPerKilo) ?
                      '-' : grossRetailPerKilo.toLocaleString(
                        undefined, { maximumFractionDigits: 2 }
                      ) }
                  </span>
                  <span className="denominator highlight">per kg</span>
                </div>
              </div>
            </div>
          </div>
          <div className="step-label">
            <div className="text">
              Usable Beef, Eblex Analysis
              <InfoIconThick onClick={ () => history.push('/') } />
            </div>
          </div>
        </div>

        <div className="boundary consumer">
          <span className="text">consumer</span>
        </div>

        <div className="section retail">
          <div className="content">
            <div className="label">
              Retail Value
              <InfoIconThick onClick={ () => history.push('/') } />
            </div>
            <div className="big-figure">
              <span className="denominator">£</span>
              <span className="big-number" data-cy="retail-value">
                { isNaN(netRetailValue) ?
                  '-' : Math.round(netRetailValue).toLocaleString() }
              </span>
            </div>
            <div className="label">Value Added</div>
            <div className="big-figure">
              <span className="denominator">£</span>
              <span className="big-number" data-cy="value-added">
                { isNaN(valueAdded) ?
                  '-' : Math.round(valueAdded).toLocaleString() }
              </span>
            </div>
            <CalculationParameter value={ retailLoss }
              onChange={ setRetailLoss }
              testId="retail-loss-input"
              inputClass="big" >
              <span>
                { isNaN(retailLoss) ? '-' : Math.round(retailLoss) }
              </span>
              <span className="denominator">%</span>
              <span className="highlight bold">Retail Losses </span>
              <span className="highlight">£ Loss</span>
            </CalculationParameter>
          </div>
          <div className="step-label">
            <GBIGradeShield grade="prime" />
            <div className="text">Consumer</div>
          </div>
        </div>

        <div className="split">
          <h2 className="split-title">Farmer and Supply Chain Sharing</h2>
          <div className="split-block farm">
            <div className="share-label">Farmer Share</div>
            <div className="percentage">
              <span className="number" data-cy="farmer-share">
                { isNaN(farmerShare) ? '-' : farmerShare }
              </span>
              <span className="denominator">%</span>
            </div>
          </div>
          <div className="split-block supply">
            <div className="share-label">Supply Chain Share</div>
            <div className="percentage">
              <span className="number" data-cy="supply-share">
                { isNaN(supplyShare) ? '-' : supplyShare }
              </span>
              <span className="denominator">%</span>
            </div>
          </div>
        </div>

      </div>
    </FarmerRestricted>
  );
};

export default ValueSharing;
