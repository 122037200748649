import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPricing } from '../actions';
import { fetchingPricings, getLatestPricing } from '../reducer';
import PricingDisplay from './PricingDisplay';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import './pricing-page.scss';
import { isAdmin } from '../../../util/jwt';
import FarmerRestricted from '../../../util/components/FarmerRestricted';

const PricingPage = () => {
  const pricing = useSelector(getLatestPricing);
  const loadingPricings = useSelector(fetchingPricings);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPricing());
  }, []);
  let content = <h1>No Price Check Available</h1>;
  if (pricing) {
    content = (
      <PricingDisplay
        pricing={ pricing }
        obfuscate={ isAdmin() === false} />
    );
  } else if (loadingPricings) {
    content = <LoadingIcon />;
  }
  return (
    <FarmerRestricted>
      <div id="pricing-page" className="farmer-content-section">
        <div className="pricing-content">
          { content }
        </div>
      </div>
    </FarmerRestricted>
  );
};

export default PricingPage;
