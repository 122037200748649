import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMobileMenuOpen } from '../../header/actions';

const FarmerMenu = ({ match }) => {
  const dispatch = useDispatch();
  const handleSelection = () => {
    dispatch(setMobileMenuOpen(false));
  };
  if (window.location.pathname === match.url) return null;
  return (
    <ul className="farmer-menu">
      <li className="header">
        <span className="top">GoodBeef Index for</span>
        <span className="bottom">Farmers</span>
      </li>
      <li className='top-level'
        data-cy="value-sharing-menu-item"
        onClick={ handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ `${match.url}/value-sharing`}
          activeClassName='active'>
          <div className="text">
            Value Sharing
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
      <li className='top-level'
        data-cy="pricing-menu-item"
        onClick={ handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ `${match.url}/pricing`}
          activeClassName='active'>
          <div className="text">
            Pricing
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
      <li className='top-level'
        data-cy="market-segmentation-menu-item"
        onClick={ handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ `${match.url}/market-segmentation`}
          activeClassName='active'>
          <div className="text">
            Market Segmentation
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    </ul>
  );
};

FarmerMenu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default FarmerMenu;
