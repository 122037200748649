import React, { useState } from 'react';
import CalculationParameter from
  '../../valueSharing/components/CalculationParameter';
import {
  CLASSIC_RETAIL,
  STANDARD_RETAIL,
  SUPERIOR_RETAIL,
  SUPREME_RETAIL
} from '../util';
import { PRIME_RETAIL } from '../../valueSharing/util';
import './market-segmentation.scss';
import MarketSegmentationRow from './MarketSegmenationRow';
import FarmerRestricted from '../../../util/components/FarmerRestricted';

const MarketSegmentation = () => {
  const [ marketSize, setMarketSize ] = useState(2000000);
  const [ supremeShare, setSupremeShare ] = useState(0.5);
  const [ primeShare, setPrimeShare ] = useState(2.5);
  const [ classicShare, setClassicShare ] = useState(7);
  const [ superiorShare, setSuperiorShare ] = useState(10);
  const [ standardShare, setStandardShare ] = useState(20);
  const handleMarketSizeChange = newValue => {
    if (isNaN(newValue)) {
      setMarketSize(0);
    } else {
      setMarketSize(newValue);
    }
  };
  return (
    <FarmerRestricted>
      <div className="farmer-content-section" id="market-segmentation">
        <h1 className="page-title" data-cy="market-segmentation-title">
          Market Segmentation
        </h1>
        <div className="animals-per-year-section">
          <div className="label">
            UK Finished Animals per year
          </div>
          <CalculationParameter
            value={ marketSize }
            onChange={ handleMarketSizeChange }
            inputClass="big"
            testId="market-size-input">
            <span className="big-number" data-cy="market-size">
              { marketSize.toLocaleString() }
            </span>
          </CalculationParameter>
        </div>
        <MarketSegmentationRow
          grade="supreme"
          share={ supremeShare }
          marketSize={ marketSize }
          updateShare={ setSupremeShare }
          retail={ SUPREME_RETAIL } />
        <MarketSegmentationRow
          grade="prime"
          share={ primeShare }
          marketSize={ marketSize }
          updateShare={ setPrimeShare }
          retail={ PRIME_RETAIL } />
        <MarketSegmentationRow
          grade="classic"
          share={ classicShare }
          marketSize={ marketSize }
          updateShare={ setClassicShare }
          retail={ CLASSIC_RETAIL } />
        <MarketSegmentationRow
          grade="superior"
          share={ superiorShare }
          marketSize={ marketSize }
          updateShare={ setSuperiorShare }
          retail={ SUPERIOR_RETAIL } />
        <MarketSegmentationRow
          grade="standard"
          share={ standardShare }
          marketSize={ marketSize }
          updateShare={ setStandardShare }
          retail={ STANDARD_RETAIL } />
      </div>
    </FarmerRestricted>
  );
};

export default MarketSegmentation;
