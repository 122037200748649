import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import FarmerDashboard from './FarmerDashboard';
import ValueSharing from '../../valueSharing/components/ValueSharing';

import FarmerMenu from '../../components/FarmerMenu';
import './farmer-section.scss';
import PricingPage from '../../pricing/components/PricingPage';
import MarketSegmentation from
  '../../margetSegmentation/components/MargetSegmenation';
import NotFound from '../../../util/components/NotFound';

const FarmerSection = ({ match, history }) => {
  const className = window.location.pathname === match.url ?
    'dashboard' : '';
  return (
    <div id="farmer-section" className={ className }>
      <FarmerMenu match={ match }/>
      <Switch>
        <Route exact path={ match.url } render={
          props => <FarmerDashboard { ...props } /> } />
        <Route path={ `${match.url}/value-sharing`} render={
          props => <ValueSharing { ...props } /> } />
        <Route path={ `${match.url}/pricing`} render={
          props => <PricingPage { ...props } /> } />
        <Route path={ `${match.url}/market-segmentation`} render={
          props => <MarketSegmentation { ...props } /> } />
        <Route component={ NotFound } />
      </Switch>
    </div>
  );
};

FarmerSection.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default FarmerSection;
