import React from 'react';
import GBIGradeShield from '../../../util/logos/GBIGradeShield';
import CalculationParameter from
  '../../valueSharing/components/CalculationParameter';
import { displaySegmentCount, displaySegmentValue } from '../util';
import PropTypes from 'prop-types';

const MarketSegmentationRow =
({ grade, share, marketSize, updateShare, retail }) => {
  const shareStr = isNaN(share) ? '-' : share.toLocaleString(undefined, {
    maximumFractionDigits: 2, minimumFractionDigits: 1
  });
  const value = isNaN(share) ? 0 : share;
  return (
    <div className="section">
      <div className="grade-container">
        <GBIGradeShield grade={ grade }></GBIGradeShield>
      </div>
      <div className="content">
        <div className="cell" data-cy={ `${grade}-market-share` }>
          <div className="cell-label">Market Share</div>
          <CalculationParameter
            value={ value }
            onChange={ updateShare }
            testId={ `${grade}-share-input`}
            inputClass="big">
            <span className="small-number">
              { shareStr }
            </span>
            <span className="denominator">%</span>
          </CalculationParameter>
        </div>
        <div className="cell">
          <div className="cell-label value">Market Retail Value</div>
          <div className="cell-content">
            <span className="denominator">£</span>
            <span
              className="small-number"
              data-cy={ `${grade}-market-value`}>
              {displaySegmentValue(marketSize, retail, share)}
            </span>
          </div>
        </div>
        <div className="cell">
          <div className="cell-label count">Finished Animals</div>
          <div className="cell-content">
            <span
              className="small-number"
              data-cy={ `${grade}-carcass-count`}>
              { displaySegmentCount(marketSize, share) }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MarketSegmentationRow.propTypes = {
  grade: PropTypes.string.isRequired,
  share: PropTypes.number.isRequired,
  marketSize: PropTypes.number.isRequired,
  updateShare: PropTypes.func.isRequired,
  retail: PropTypes.number.isRequired
};

export default MarketSegmentationRow;
