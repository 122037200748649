import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './unavailable-menu-option.scss';
import Signup from '../../../user/signup/components/Signup';

const UnavailableMenuOption = ({ children, hoverText, id }) => {
  const wrapperRef = useRef(null);
  const [ showing, setShowing ] = useState(false);
  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowing(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const hoverClass = showing ? 'hover-text showing' : 'hover-text hidden';
  return (
    <div className="unavailable-menu-option"
      ref={ wrapperRef }
      onClick={ () => setShowing(!showing) }
      data-cy={ `unavailable-menu-option-${id}`}>
      { children }
      <div className={ hoverClass }
        data-cy={ `unavailable-menu-text-${id}`}>
        <h5>Restricted</h5>
        <p>{ hoverText }</p>
        <div className="options">
          <Link to="/login" data-cy={ `login-link-${id}`}>
            <div className="option login">login</div>
          </Link>
          <Signup id={ `join-link-${id}` } buttonLabel="register" />
        </div>
      </div>
    </div>
  );
};

UnavailableMenuOption.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hoverText: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default UnavailableMenuOption;
