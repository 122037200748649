export const getDeadWeight = (liveWeight, killOutLoss) => {
  return liveWeight * (100 - killOutLoss) / 100;
};
export const getHungWeight = (deadWeight, hangingLoss) => {
  return deadWeight * (100 - hangingLoss) / 100;
};
export const getButcheredWeight = (hungWeight, butcheringLoss) => {
  return hungWeight * (100 - butcheringLoss) / 100;
};
export const getRetailKilo = (grossRetailValue, butcheredWeight) => {
  return grossRetailValue / butcheredWeight;
};
export const getFarmGateTotal = (farmCost, farmReturn) => {
  const val = Math.round(farmCost * (1 + (farmReturn / 100)));
  return isNaN(val) ? 0 : val;
};
export const getFarmGateKilo = (farmCost, farmReturn, deadWeight) => {
  let farmGateKilo = farmCost * (1 + (farmReturn / 100)) / deadWeight;
  farmGateKilo = farmGateKilo.toLocaleString(
    'en-GB', { maximumFractionDigits: 2 }
  );
  return farmGateKilo === 'NaN' ? '0' : farmGateKilo;
};
export const getNetRetail = (grossRetailValue, retailLoss) => {
  return grossRetailValue * (100 - retailLoss) / 100;
};
export const getValueAdded = (netRetailValue, farmGateTotal) => {
  return netRetailValue - farmGateTotal;
};
export const getFarmerShare = (farmGateTotal, netRetailValue) => {
  return Math.round(farmGateTotal / netRetailValue * 100);
};
export const getSupplyShare = (valueAdded, netRetailValue) => {
  return Math.round(valueAdded / netRetailValue * 100);
};
const EBLEX_CUTS = 211;
export const PRIME_RETAIL = 4398;
export const getEblexRetailValue = butcheredWeight => {
  return PRIME_RETAIL * butcheredWeight / EBLEX_CUTS;
};
