import React, { useEffect } from 'react';
import './dashboard.scss';
import MarketSegmentationMenuItem from './MarketSegmentationMenuItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UnavailableMenuOption from './UnavailableMenuOption';
import AdminMenuItem from './AdminMenuItem';
import ValueSharingMenuItem from './ValueSharingMenuItem';
import PricingMenuItem from './PricingMenuItem';
import { canViewFarmerPages } from '../../../util/jwt';

const FarmerDashboard = ({ match, location }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const meta = 'GoodBeef Index provides a range of resources ' +
    'for beef farmers. Learn how quality beef is measured, ' +
    'grade your animals, and get industry insights';
  const adminText = `
    Member farms can upload and grade their animals,
    download qr codes for their animals and manage
    their account from here.
  `;
  const valueSharingText = `
    Join the GoodBeef Index and see how value is shared throughout the 
    supply chain
  `;
  const pricingText = `
    See up to date farm gate price per kilo across a range of retailers.
  `;
  const marketSegmentationText = `
    Breakdown the UK beef market to see the size of each premium beef segment
  `;
  let admin = (
    <UnavailableMenuOption hoverText={ adminText } id="admin" >
      <AdminMenuItem />
    </UnavailableMenuOption>
  );
  let valueSharing = (
    <UnavailableMenuOption hoverText={ valueSharingText } id="value-sharing" >
      <ValueSharingMenuItem />
    </UnavailableMenuOption>
  );
  let pricing = (
    <UnavailableMenuOption hoverText={ pricingText } id="pricing" >
      <PricingMenuItem />
    </UnavailableMenuOption>
  );
  let marketSegmentation = (
    <UnavailableMenuOption hoverText={ marketSegmentationText }
      id="market-segments" >
      <MarketSegmentationMenuItem />
    </UnavailableMenuOption>
  );
  if (canViewFarmerPages()) {
    admin = (
      <Link className="menu-item" to="/account">
        <AdminMenuItem />
      </Link>
    );
    valueSharing = (
      <Link className="menu-item" to={ `${match.url}/value-sharing`}>
        <ValueSharingMenuItem />
      </Link>
    );
    pricing = (
      <Link className="menu-item" to={ `${match.url}/pricing`}>
        <PricingMenuItem />
      </Link>
    );
    marketSegmentation = (
      <Link className="menu-item" to={ `${match.url}/market-segmentation`}>
        <MarketSegmentationMenuItem />
      </Link>
    );
  }
  return (
    <div id="farmer-dashboard"className="main-dashboard">
      <Helmet>
        <title>
          GoodBeef Index Farmer Dashboard
        </title>
        <meta name="description" content={ meta } />
        <link rel="canonical"
          href={ `${window.location.origin}/${location.pathname}`} />
      </Helmet>
      <h2 className="sub-title">GoodBeef Index for farmers</h2>
      <h1 className="main-title">
        a fair price for an authenticated grade of quality
      </h1>
      <div className="menu">
        { admin }
        { valueSharing }
        { pricing }
        { marketSegmentation }
      </div>
    </div>
  );
};

FarmerDashboard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default FarmerDashboard;
