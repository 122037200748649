export const SUPREME_RETAIL = 5090;
export const CLASSIC_RETAIL = 2950;
export const SUPERIOR_RETAIL = 2562;
export const STANDARD_RETAIL = 2268;

export const calcSegmentValue = (marketSize, retailValue, segmentSize) => {
  return marketSize * retailValue * segmentSize / 100;
};

export const displaySegmentValue = (marketSize, retailValue, segmentSize) => {
  if (typeof marketSize !== 'number' || isNaN(marketSize) ||
    typeof retailValue !== 'number' || isNaN(retailValue) ||
    typeof segmentSize !== 'number' || isNaN(segmentSize)) {
    return '-';
  }
  const value = calcSegmentValue(marketSize, retailValue, segmentSize);
  const millions = Math.round(value / 1000000);
  return millions.toLocaleString() + 'm';
};

export const calcSegmentCount = (marketSize, segmentSize) => {
  return marketSize * segmentSize / 100;
};

export const displaySegmentCount = (marketSize, segmentSize) => {
  if (typeof marketSize !== 'number' || isNaN(marketSize) ||
  typeof segmentSize !== 'number' || isNaN(segmentSize)) {
    return '-';
  }
  const count = calcSegmentCount(marketSize, segmentSize);
  const thousands = Math.round(count / 1000);
  return thousands.toLocaleString() + 'k';
};
