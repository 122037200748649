import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './calc-param.scss';

const CalculationParameter = (
  { children, value, onChange, inputClass, testId }
) => {
  const inputRef = useRef();
  const [ isErrored, setIsErrored ] = useState(false);
  const handleChange = () => {
    try {
      const newValue = inputRef.current.value;
      onChange(parseFloat(newValue || 0));
    } catch (error) {
      setIsErrored(true);
    }
  };
  const className = isErrored ? 'error ' + inputClass : inputClass;
  return (
    <div className="calculation-parameter">
      <input ref={ inputRef }
        defaultValue={ value }
        className={ className }
        data-cy={ testId || 'calculation-parameter' }
        onChange={ handleChange }/>
      { children }
    </div>
  );
};

CalculationParameter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  inputClass: PropTypes.string,
  testId: PropTypes.string
};

export default CalculationParameter;
