import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../goodBeefIndex/gbiHistory';
import { showNotification } from '../../notifications/actions';
import { canViewFarmerPages } from '../jwt';
import PropTypes from 'prop-types';

const FarmerRestricted = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!canViewFarmerPages()) {
      dispatch(showNotification(
        'Restricted',
        'Please login with a valid farmer account',
        'error'
      ));
      history.push('/login');
    }
  }, []);
  return (
    <Fragment>{ children }</Fragment>
  );
};

FarmerRestricted.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default FarmerRestricted;
